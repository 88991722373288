import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import QuotesLeft from '@/assets/img/ico_quotes_left.png';
import QuotesRight from '@/assets/img/ico_quotes_right.png';
import Arrow from '@/assets/img/icons/ico_next_contents@2x.png';
import CardImg1 from '@/assets/img/tmp/child-participation/childcouncil_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/tmp/child-participation/childcouncil_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/tmp/child-participation/childcouncil_card_img3@2x.jpg';
import CardImg4 from '@/assets/img/tmp/child-participation/childcouncil_card_img4@2x.jpg';
import CardImg5 from '@/assets/img/tmp/child-participation/childcouncil_card_img5@2x.jpg';
import CardImg6 from '@/assets/img/tmp/child-participation/childcouncil_card_img6@2x.jpg';
import CouncilImg from '@/assets/img/tmp/child-participation/childcouncil_council@2x.png';
import TopImg from '@/assets/img/tmp/child-participation/childcouncil_img1@2x.jpg';
import CouncilLogo from '@/assets/img/tmp/child-participation/childcouncil_log@2x.png';
import SwiperBasic from '@/components//Swiper/SwiperBasic';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionContact, SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    dec: `유니세프 아동의회 1기 '아동정책제안 간담회'`,
    img: CardImg1,
  },
  {
    id: 2,
    dec: `아동정책제안서 발간`,
    img: CardImg2,
  },
  {
    id: 3,
    dec: `지지서명 캠페인 진행`,
    img: CardImg3,
  },
  {
    id: 4,
    dec: `토론회 및 포럼 진행`,
    img: CardImg4,
  },
  {
    id: 5,
    dec: `아동권리 교육`,
    img: CardImg5,
  },
  {
    id: 6,
    dec: `홍보 콘텐츠 제작`,
    img: CardImg6,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리교육팀`,
    tel: `02-724-8572`,
    email: `cp@unicef.or.kr`,
  },
];

const Content = styled.div`
  padding-top: 152px;
  position: relative;

  .logo {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 145px;
  }

  .flex-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 100px;

    &::before {
      content: '';
      width: 1280px;
      height: 100%;
      background: #e2f0f6;
      border-radius: 0 0 0 100px;
      position: absolute;
      left: 240px;
      top: 0;
      z-index: -1;
    }

    .col-img {
      width: 48.6%;
    }

    .col-dec {
      padding-left: 32px;
      width: 51.4%;
      max-width: 480px;

      dt {
        border-bottom: 1px solid #1cabe2;
        padding-bottom: 8px;

        ${Tit} {
          line-height: 1.6;
        }
      }

      dd {
        padding-top: 32px;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-top: 124px;

    .logo {
      max-width: 92px;
    }

    .flex-container {
      flex-wrap: wrap;
      margin-left: -20px;
      margin-right: -20px;
      padding-bottom: 0;

      &::before {
        display: none;
      }

      .col-img,
      .col-dec {
        width: 100%;
      }

      .col-dec {
        padding-top: 24px;
        padding-left: 20px;
        padding-bottom: 91px;
        max-width: 100%;
        background: #e2f0f6;

        dd {
          padding-top: 24px;
        }
      }
    }
  }
`;
const SectionTop = styled(Section)``;

const DlContainer = styled.div`
  dl {
    display: flex;
    align-items: flex-start;
    padding: 32px 0;
    border-bottom: 1px solid #f8f9fa;

    &:first-child {
      padding-top: 0;
    }

    dt {
      width: 34%;

      ${Tit} {
        position: relative;
        padding-left: 25px;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: #1cabe2;
          position: absolute;
          left: 0;
          top: 15px;
        }

        strong {
          color: #1cabe2;
        }
      }
    }
    dd {
      width: 66%;
      line-height: 1.7;

      .blit-list {
        li {
          &::before {
            background: #2d2926;
          }
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    dl {
      dt {
        ${Tit} {
          &::before {
            top: 11px;
          }
        }
      }
      dd {
        padding-left: 12px;

        .blit-list {
          margin-left: -12px;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    dl {
      flex-wrap: wrap;

      dt,
      dd {
        width: 100%;
      }
      dd {
        margin-top: 16px;
      }
    }
  }
`;
const StepContainer = styled.ul`
  margin-top: 64px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 28px 0;

  &::before {
    content: '';
    display: block;
    width: 19220px;
    height: 100%;
    background: #e5e6e8;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }

  & > li {
    width: 20%;
    line-height: 2;
    text-align: center;
    font-weight: 700;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(${Arrow}) center no-repeat;
      background-size: cover;
      position: absolute;
      right: -12px;
      top: 50%;
      transform: translate(0, -50%) rotate(-90deg);
    }

    &:last-child::before {
      display: none;
    }
  }

  ${breakpoint(`tablet`)} {
    padding: 8px 0;
    margin-top: 48px;
    flex-wrap: wrap;
    justify-content: center;

    & > li {
      width: 33.33%;
      margin: 16px 0;

      &:nth-child(3)::before {
        display: none;
      }
    }
  }
`;
const SectionHow = styled(Section)`
  padding-bottom: 0 !important;
`;

const ThumbnailContainer = styled.div`
  .list-item {
    margin-bottom: 48px;
    width: 33.33%;

    .img-box {
      padding-top: 72.16%;
    }
    .dec-wrap {
      background: transparent;
      padding: 0;
      padding-top: 24px;

      .dec {
        margin-top: 0;
        font-weight: 700;
      }
    }
  }

  ${breakpoint(`mobile`)} {
    .list-item {
      margin-bottom: 23px;
      width: 50%;

      .dec-wrap {
        padding-top: 16px;
      }
    }
  }
`;
const ColFlex = styled.div`
  display: flex;
  align-items: center;
  .col-img {
    width: 39%;
  }
  .col-dec {
    width: 61%;
    padding-left: 47px;

    .blit-list {
      margin-top: 24px;
      li {
        line-height: 1.7;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: #2d2926;
        }
      }
    }
  }

  ${breakpoint(`mobile`)} {
    margin-top: 24px;
    flex-wrap: wrap;
    .col-img,
    .col-dec {
      width: 100%;
    }
    .col-dec {
      padding-left: 0;
    }
  }
`;
const SectionBg = styled(Section)`
  background: #f8f9fa;
  padding: 64px 0 40px !important;
`;

const SectionQuote = styled(Section)`
  padding-top: 34px !important;
  padding-bottom: 152px !important;

  .quote {
    width: 97%;
    background: #1cabe2;
    position: relative;
    margin-top: 273px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: #b2def1;
      position: absolute;
      left: 31px;
      top: 32px;
      z-index: -1;
    }

    .dec-wrap {
      color: #fff;
      padding: 57px 70px 55px;
      text-align: center;
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 50px;
        height: 46px;
        position: absolute;
      }
      &::before {
        background: url(${QuotesLeft}) no-repeat center center;
        background-size: cover;
        left: 64px;
        top: 34px;
      }

      &::after {
        background: url(${QuotesRight}) no-repeat center center;
        background-size: cover;
        bottom: 53px;
        right: 54px;
      }

      .dec {
        margin-top: 24px;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-bottom: 92px !important;

    .quote {
      width: auto;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: 145px;

      &::before {
        left: 0;
        top: 32px;
      }

      .dec-wrap {
        padding: 64px 30px;

        &::before,
        &::after {
          width: 41px;
          height: 38px;
        }
        &::before {
          left: 20px;
          top: 14px;
        }

        &::after {
          bottom: 20px;
          right: 19px;
        }

        .dec {
          margin-top: 16px;
        }
      }
    }
  }
`;

const SwiperCustom = styled(SwiperBasic)`
  padding: 0;
  overflow: hidden;
`;
const DecFlex = styled.div`
  align-items: flex-start;
  dl {
    margin-bottom: 96px;
    width: 33.33%;

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      margin-bottom: 64px;
    }

    dt {
      margin-bottom: 8px;
      display: flex;
      align-items: flex-start;

      ${Tit} {
        line-height: 1.2;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
          margin-top: 20px;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    dl {
      margin-bottom: 48px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
      dt {
        ${Tit} {
          margin-right: 16px;

          &:last-child {
            margin-top: 5px;
          }
        }
      }
    }
  }
`;

const SectionRule = styled(Section)`
  background: #e2f0f6;

  ${SectionHeader} {
  }
  .tip {
    color: #b7b8ba;
    font-size: 14px;
  }

  ${breakpoint(`tablet`)} {
    .tip {
      margin-top: 48px;
    }
  }
  ${breakpoint(640)} {
    .tip {
      font-size: 12px;
    }
  }
`;

const ChildCouncil: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A018'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="아동의회"
      description="for every child, children's council"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  우리는 아동권리 증진 활동을 <br />
                  펼치는 유니세프의 파트너입니다
                </PageComponent>
              </Tit>
            </h2>
          </SectionHeader>

          <Content>
            <Image
              pcSrc={CouncilLogo}
              mobileSrc={CouncilLogo}
              className="logo"
            />
            <div className="flex-container">
              <div className="col-img">
                <Image pcSrc={TopImg} mobileSrc={TopImg} />
              </div>
              <dl className="col-dec">
                <dt>
                  <p className="dec">UNICEF Korea Children's Council</p>
                  <Tit size="s3-1" color="sky">
                    유니세프 아동의회
                  </Tit>
                </dt>
                <dd>
                  <p className="dec">
                    유니세프와 함께 사회 문제를 살피고 해결책을 제안하며, 아동을
                    보호하고 지원하는 환경이 구축될 수 있도록 권리 주체자로서
                    영향력을 행사하여 다양한 활동을 진행합니다.
                  </p>
                </dd>
              </dl>
            </div>
          </Content>
        </Container>
      </SectionTop>

      <SectionHow className="by-sub-main-layout">
        <Container>
          <h2 className="a11y">아동권리 증진 활동</h2>

          <DlContainer>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>누가</strong> 할 수 있나요?
                </Tit>
              </dt>
              <dd>
                만 <strong>11세~15세 미만</strong>의 대한민국 아동 누구나
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>어떤 활동</strong>을 하나요?
                </Tit>
              </dt>
              <dd>
                <ul className="blit-list">
                  <li>
                    유니세프가 발표한 아동권리 과제를 중심으로 사회 문제에 대해
                    조사, 연구, 분석하고 해결책 제안
                  </li>
                  <li>
                    모든 아동을 보호하고 지원하는 환경이 구축될 수 있도록 정책
                    결정권자, 미디어, 중앙정부, <br />또 다른 대한민국의 어린이
                    및 청소년과의 소통을 통해 국내 및 국제 차원에서의 아동권리
                    옹호 활동 진행
                  </li>
                </ul>
              </dd>
            </dl>
          </DlContainer>
          <StepContainer>
            <li>주제 선정</li>
            <li>
              온/오프라인 <br />
              워크숍
            </li>
            <li>
              정책제안서 <br />
              작성
            </li>
            <li>
              지지서명 <br />
              캠페인
            </li>
            <li>
              발간 기념 <br />
              간담회
            </li>
          </StepContainer>
        </Container>
      </SectionHow>

      <SectionBg className="by-sub-main-layout">
        <Container>
          <ThumbnailContainer>
            <ThumbnailFlex thumbs={card} />
          </ThumbnailContainer>
          <ColFlex>
            <div className="col-img">
              <Image pcSrc={CouncilImg} mobileSrc={CouncilImg} />
            </div>
            <div className="col-dec">
              <Tit size="s4-1" color="sky">
                2020년 유니세프 아동의회 1기
              </Tit>
              <ul className="blit-list">
                <li>'아동권리와 기후 위기'를 주제로 5개의 정책 제안</li>
                <li>온라인 지지서명 캠페인 진행 (1,764명의 아동 동의)</li>
                <li>정책결정권자와의 비대면 만남으로 해결방안 모색</li>
              </ul>
            </div>
          </ColFlex>
        </Container>
      </SectionBg>

      <SectionQuote className="by-sub-main-layout">
        <Container>
          <DlContainer>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>언제, 어떻게</strong> 지원할 수 있나요?
                </Tit>
              </dt>
              <dd>
                <strong>연 1회</strong> 웹사이트 통해 모집 <br />
                심사를 통해 약 <strong>30명</strong>의 아동의회 의원을 선발하여
                {` `}
                <strong>1년 간</strong> 활동
              </dd>
            </dl>
          </DlContainer>
          <div className="quote">
            <div className="dec-wrap">
              <Tit size="s4" color="white">
                18세 미만의 모든 아동은 <br />
                자신의 삶과 관련된 의사 결정 과정에 자신의 의견을 말하고 표현할
                권리가 있으며, <br />
                정부가 사회복지기관, 법원 등 아동과 관련된 일을 하는 모든 기관은
                <br />
                아동에게 무엇이 가장 이익이 되는지 그 점을 제일 먼저 생각해야
                한다.
              </Tit>
              <p
                className="dec"
                css={`
                  padding-top: 24px;
                `}
              >
                -유엔아동권리협약 제 3조&제 12조-
              </p>
            </div>
          </div>
        </Container>
      </SectionQuote>

      <SectionRule className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                우리는 아동참여 <br className="m-hide" />
                원칙 9가지를 준수합니다
              </Tit>
            </h2>
          </SectionHeader>

          <DecFlex className="flex align-start m-hide">
            <dl>
              <dt>
                <Tit size="s1" color="sky" weight="thin">
                  1
                </Tit>
                <Tit size="s4-1" color="sky">
                  투명성과 충분한 정보
                </Tit>
              </dt>
              <dd>
                아동은 자신의 참여권에 대해 이해하고, 의견을 표시하는 방법과
                목적, 효과에 대해 알아야 합니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s1" color="sky" weight="thin">
                  2
                </Tit>
                <Tit size="s4-1" color="sky">
                  자발적인 참여
                </Tit>
              </dt>
              <dd>
                아동은 자신의 의사와 다른 의견을 표현하도록 강요받아서는 안
                됩니다. 아동은 원한다면 언제든지 자신의 의견을 철회하거나 참여
                활동을 중단할 수 있습니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s1" color="sky" weight="thin">
                  3
                </Tit>
                <Tit size="s4-1" color="sky">
                  서로에 대한 존중
                </Tit>
              </dt>
              <dd>
                아동은 자신의 관점을 존중받아야 하며, 다양한 활동의 기회를
                누려야 합니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s1" color="sky" weight="thin">
                  4
                </Tit>
                <Tit size="s4-1" color="sky">
                  적절성과 관련성
                </Tit>
              </dt>
              <dd>
                활동 주제는 아동의 삶과 관련 있는 것이 적절합니다. 주제를
                선정하는 데 있어 아동의 의견이 반영돼야 합니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s1" color="sky" weight="thin">
                  5
                </Tit>
                <Tit size="s4-1" color="sky">
                  아동친화적인 접근과 전개
                </Tit>
              </dt>
              <dd>
                참여 활동을 위한 모든 방식과 환경은 아동의 관점에서 익숙하고
                친근한 방식으로 이루어져야 합니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s1" color="sky" weight="thin">
                  6
                </Tit>
                <Tit size="s4-1" color="sky">
                  균등한 기회와 포용성
                </Tit>
              </dt>
              <dd>
                성별, 나이, 문화, 경제 수준에 관련 없이, 모든 아동은 활동에
                참여할 수 있는 기회를 제공받아야 합니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s1" color="sky" weight="thin">
                  7
                </Tit>
                <Tit size="s4-1" color="sky">
                  역량 강화를 위한 지원
                </Tit>
              </dt>
              <dd>
                아동의 참여 활동을 돕는 성인은 이에 맞는 적절한 역량을 지니고
                있어야 합니다. 아동은 다양한 참여 활동에 효과적으로 참여할 수
                있도록 훈련을 받아야 합니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s1" color="sky" weight="thin">
                  8
                </Tit>
                <Tit size="s4-1" color="sky">
                  안전 유지와 위험에 대한 민감성
                </Tit>
              </dt>
              <dd>
                아동은 자신이 보호받을 권리가 있다는 것을 알아야 하며, 최대한
                안전한 환경을 제공받아야 하고, 위험에 처했을 경우 즉시 도움을
                받아야 합니다.
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s1" color="sky" weight="thin">
                  9
                </Tit>
                <Tit size="s4-1" color="sky">
                  결과에 대한 책임감
                </Tit>
              </dt>
              <dd>
                아동은 자신의 활동에 대한 명확한 피드백을 받아야하며, 자신이
                활동을 통해 어떠한 변화가 있었는지를 알아야 합니다.
              </dd>
            </dl>
          </DecFlex>

          <DecFlex className="pc-hide">
            <SwiperCustom>
              <Swiper
                slidesPerView={1}
                navigation
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide>
                  <dl>
                    <dt>
                      <Tit size="s1" color="sky" weight="thin">
                        1
                      </Tit>
                      <Tit size="s4-1" color="sky">
                        투명성과 충분한 정보
                      </Tit>
                    </dt>
                    <dd>
                      아동은 자신의 참여권에 대해 이해하고, 의견을 표시하는
                      방법과 목적, 효과에 대해 알아야 합니다.
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <Tit size="s1" color="sky" weight="thin">
                        2
                      </Tit>
                      <Tit size="s4-1" color="sky">
                        자발적인 참여
                      </Tit>
                    </dt>
                    <dd>
                      아동은 자신의 의사와 다른 의견을 표현하도록 강요받아서는
                      안 됩니다. 아동은 원한다면 언제든지 자신의 의견을
                      철회하거나 참여 활동을 중단할 수 있습니다.
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <Tit size="s1" color="sky" weight="thin">
                        3
                      </Tit>
                      <Tit size="s4-1" color="sky">
                        서로에 대한 존중
                      </Tit>
                    </dt>
                    <dd>
                      아동은 자신의 관점을 존중받아야 하며, 다양한 활동의 기회를
                      누려야 합니다.
                    </dd>
                  </dl>
                </SwiperSlide>
                <SwiperSlide>
                  <dl>
                    <dt>
                      <Tit size="s1" color="sky" weight="thin">
                        4
                      </Tit>
                      <Tit size="s4-1" color="sky">
                        적절성과 관련성
                      </Tit>
                    </dt>
                    <dd>
                      활동 주제는 아동의 삶과 관련 있는 것이 적절합니다. 주제를
                      선정하는 데 있어 아동의 의견이 반영돼야 합니다.
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <Tit size="s1" color="sky" weight="thin">
                        5
                      </Tit>
                      <Tit size="s4-1" color="sky">
                        아동친화적인 접근과 전개
                      </Tit>
                    </dt>
                    <dd>
                      참여 활동을 위한 모든 방식과 환경은 아동의 관점에서
                      익숙하고 친근한 방식으로 이루어져야 합니다.
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <Tit size="s1" color="sky" weight="thin">
                        6
                      </Tit>
                      <Tit size="s4-1" color="sky">
                        균등한 기회와 포용성
                      </Tit>
                    </dt>
                    <dd>
                      성별, 나이, 문화, 경제 수준에 관련 없이, 모든 아동은
                      활동에 참여할 수 있는 기회를 제공받아야 합니다.
                    </dd>
                  </dl>
                </SwiperSlide>
                <SwiperSlide>
                  <dl>
                    <dt>
                      <Tit size="s1" color="sky" weight="thin">
                        7
                      </Tit>
                      <Tit size="s4-1" color="sky">
                        역량 강화를 위한 지원
                      </Tit>
                    </dt>
                    <dd>
                      아동의 참여 활동을 돕는 성인은 이에 맞는 적절한 역량을
                      지니고 있어야 합니다. 아동은 다양한 참여 활동에 효과적으로
                      참여할 수 있도록 훈련을 받아야 합니다.
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <Tit size="s1" color="sky" weight="thin">
                        8
                      </Tit>
                      <Tit size="s4-1" color="sky">
                        안전 유지와 위험에 대한 민감성
                      </Tit>
                    </dt>
                    <dd>
                      아동은 자신이 보호받을 권리가 있다는 것을 알아야 하며,
                      최대한 안전한 환경을 제공받아야 하고, 위험에 처했을 경우
                      즉시 도움을 받아야 합니다.
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <Tit size="s1" color="sky" weight="thin">
                        9
                      </Tit>
                      <Tit size="s4-1" color="sky">
                        결과에 대한 책임감
                      </Tit>
                    </dt>
                    <dd>
                      아동은 자신의 활동에 대한 명확한 피드백을 받아야하며,
                      자신이 활동을 통해 어떠한 변화가 있었는지를 알아야 합니다.
                    </dd>
                  </dl>
                </SwiperSlide>
              </Swiper>
            </SwiperCustom>
          </DecFlex>

          <p className="tip">
            출처 : 유엔아동권리협약 일반논평 제 12호 / Advocacy Toolkit (UNICEF,
            2010)
          </p>
        </Container>
      </SectionRule>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A018" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>

      <SectionContact className="by-sub-main-layout">
        <Container>
          <header>
            <h2 className="a11y">문의</h2>
          </header>
          <ContactUs itemData={contactData} />
        </Container>
      </SectionContact>
    </LayoutWithTitle>
  );
};

export default ChildCouncil;
